import {environment} from "../../../environments/environment";
import * as moment from 'moment-timezone';
import {TimeZoneDto} from '../dtos/on-board.dto';
import {FormGroup} from '@angular/forms';

export const AppUtils = {
  getPortalUrl() {
    return location.origin;
  },
  isRequestForLmsAPI(requestUrl: string) {
    return requestUrl.includes(environment.api_url);
  },
  formatAges(from: string, to: string) {
    let formattedAges = '';
    if (from && to) {
      formattedAges = `${from} to ${to}`;
    } else if (!from && !to) {
      return formattedAges;
    } else {
      from = from ? ` > ${from} ` : '';
      to = to ? ` < ${to}` : '';
      formattedAges = from || to;
    }

    return formattedAges;
  },
  getEnumKeysAndValuesArray(enumObj: any): { key: string; value: number }[] {
    return Object.keys(enumObj)
      .filter((key) => isNaN(Number(key)))
      .map((key) => ({
        key,
        value: enumObj[key as keyof typeof enumObj]
      }));
  },

  getTimezones() {
    let timezones: TimeZoneDto[] = [] as TimeZoneDto[];
    let timezoneNames = moment.tz.names();

    // Sort the timezone names by UTC offset
    timezoneNames.sort((a, b) => {
      return moment.tz(a).utcOffset() - moment.tz(b).utcOffset();
    });
    timezoneNames.forEach(zone => {
      timezones.push({
        tzName: `(UTC ${moment.tz(zone).format('Z')}) ${zone}, ${moment.tz(zone).format('z')}`,
        tzAbbrv: moment.tz(zone).zoneAbbr(),
        tzOffSet: moment.tz(zone).utcOffset()
      });
    });
    return timezones as TimeZoneDto[];
  },

  isFormEmpty(form: FormGroup): boolean {
    const formValues = form.value;
    for (const key in formValues) {
      if (formValues[key] !== null && formValues[key] !== '') {
        return false;
      }
    }
    return true;
  },

  formatTime(seconds: number): string {
    const minutes: number = Math.floor(seconds / 60);
    const secondsLeft: number = seconds % 60;
    return `${this.pad(minutes)}:${this.pad(secondsLeft)}`;
  },
  pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  },
  generatePin() {
    return Math.floor(Math.random() * 1000000);
  }

}
