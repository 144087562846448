export class CompanyConfigDto {
  brandingTitle: string = "";
  brandingFavicon: string = "";
  loginTemplate: string = "";
  loginImage: string = "";
  loginImageSize: string = "";
  loginImageType: string = "";
  loginLogo: string = "";
  companyLogo: string = "";
  loginBgColor: string = "";
  loginBgColorRight: string = "";
  loginFontColor: string = "";
  loginButtonColor: string = "";
  bodyFontSize: string = "";
  loginFirstSvg: string = "";
  loginSecondSvg: string = "";
  loginThirdSvg: string = "";
  loginFourthSvg: string = "";
  fontFamily: string = "";
  primaryColor: string = "";
  logoBg: string = "";
  websiteLogoBg = "";
  isOnBoard: boolean = false;
  isWhiteLabeled: boolean;
}
