import {patchState, signalStore, withHooks, withMethods, withState} from "@ngrx/signals";
import {CompanyConfigDto} from "../../shared/dtos/company-config-dto";
import {inject} from "@angular/core";
import {CompanyService} from "../services/company.service";
import {AppUtils} from "../../shared/utils/app-utilities";

export type CompanyConfigState = {
  configs: CompanyConfigDto,
  isLoading: boolean
}
export const companyConfigStore = signalStore(
  {providedIn: 'root'},
  withState<CompanyConfigState>({configs: new CompanyConfigDto(), isLoading: true}),
  withMethods((store) => {
    const companyConfigService = inject(CompanyService);
    return {
      async loadConfigs(url: string) {
        patchState(store, {isLoading: true});
        //get from API
        const apiConfigs = await companyConfigService.getConfigs(url);
        if (apiConfigs) {
          patchState(store, {configs: apiConfigs});
        }

        patchState(store, {isLoading: false});
      },
      Update(prop: Partial<CompanyConfigDto>) {
        patchState(store, (state) => ({configs: {...state.configs, ...prop}}));
      }
    }
  }),
  withHooks({
    async onInit(state) {
      await state.loadConfigs(AppUtils.getPortalUrl());
    }
  })
)
